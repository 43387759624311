export default {
  // 登录页
  loginPage: {
    area: 'Региональный код',
    areaMsg: 'Пожалуйста, выберите региональный код',
    phone: 'Телефон',
    phoneMsg: 'Пожалуйста, введите номер телефона',
    name: 'Никнейм',
    nameMsg: 'Пожалуйста, введите никнейм',
    password: 'Пароль',
    passwordMsg: 'Пожалуйста, введите пароль',
    confirmPassword: 'Подтверждение пароля',
    confirmpsd: 'Подтверждение пароля', // 注意：这里与confirmPassword重复，建议统一
    code: 'Рекомендательный код',
    codeMsg: 'Пожалуйста, введите рекомендательный код',
    termes: 'Регистрация означает, что вы согласны с нашими условиями и положениями',
    rappelez: 'Запомнить меня',
    register: 'Регистрация',
    login: 'Вход',
    account: 'У вас уже есть аккаунт?',
    gologing: 'Перейти к вход',
    noAccount: 'Нет аккаунта?',
    goregister: 'Перейти к регистрации',
    pasdMage: 'Пароль должен содержать 6-20 букв и цифр',
    loginSuccess: 'Вход выполнен успешно',
    registerSuccess: 'Регистрация успешна, пожалуйста, войдите',
    clause: 'Пожалуйста, подтвердите согласие с условиями!',
    noPasd: 'Пароли не совпадают!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Пожалуйста, введите международный региональный код',
    sub: 'Ок'
  },
  // tabbar页
  tabbarPage: {
    home: 'Домой',
    order: 'Заказы',
    trade: 'Торговля',
    team: 'Команда',
    user: 'Пользователь'
  },
  // 首页
  homePage: {
    income: 'Накопленный доход',
    myService: 'Мои услуги',
    about: 'О нас',
    help: 'Помощь',
    customer: 'Обслуживание клиентов',
    partner: 'Партнеры'
  },
  // 订单页
  orderPage: {
    order: 'Заказ',
    notFinished: 'Не завершен',
    completed: 'Завершен',
    more: 'Нет больше',
    orderNum: 'Номер заказа',
    tradeTime: 'Время транзакции',
    amount: 'Сумма',
    newTaskNum: 'Количество текучих задач',
    expectedReturns: 'Ожидаемый доход',
    noData: 'Нет данных',
    placeOrder: 'Отправить заказ',
    cancel: 'Отменить',
    close: 'Закрыть',
    noBalance: 'Недостаточно средств на счет',
    needRecharge: 'Необходимо пополнение',
    gorecharge: 'Перейти к пополнению?'
  },
  // 交易页
  tradePage: {
    title: 'Торговля',
    account: 'Баланс на счет',
    introduceTitle: 'Введение в торговля',
    introduceText: 'TiktokShop ежедневно использует отзывы TiktokShop для демонстрации продуктов TiktokShop, которые требуют улучшения. Пользователь просто нажимает на заказ, и система автоматически генерирует подписи заказа. Пользователь оплачивает сумма заказа через блокчейн USDT, при этом получает комиссию более 0.6% каждый раз, система случайным образом распределяет крупные награды заказы.',
    Completed: 'Количество завершенных',
    totalTask: 'Общее количество задач',
    start: 'Начать сопоставление',
    dayProfit: 'Сегодняшний доход',
    tradeTime: 'Время транзакции',
    price: 'Сумма',
    profit: 'Ожидаемый доход',
    startTrade: 'Начать торговля',
    close: 'Отменить'
  },
  // 团队页
  teamPage: {
    title: 'Команда',
    money: 'Сумма',
    commission: 'Комиссия',
    receive: 'Получить',
    myTeam: 'Моя команда',
    total: 'Обшее количество',
    user: 'Пользователь',
    contribution: 'Вклад (задачи)',
    status: 'Статус'
  },
  // 我的页面
  userPage: {
    noLogin: 'Не вошли',
    id: 'Идентификатор',
    account: 'Мой аккаунт',
    freezeAmount: 'Замороженная сумма',
    withdrawal: 'Вывести средства',
    recharge: 'Пополнение',
    invitation: 'Пригласить ваших друзей',
    invitationText: 'Каждая прибыль, заработанная друзьями после регистрации, вам даст соответствуююю комиссию',
    nowInvitation: 'Пригласить сейчас',
    userInfo: 'Личная информация',
    financial: 'Финансовые операции',
    rechargeRecord: 'Записи пополнения',
    withdrawalRecord: 'Записи вывода средств',
    lang: 'Язык',
    out: 'Выйти',
    copy: 'Копирование выполнено успешно',
    load: 'Загрузка...',
    outMsg: 'Вы уверены, что хотите выйти?'
  },
  // 提现页面
  withdrawalPage: {
    title: 'Вывести средства',
    mode: 'Способ вывода',
    address: 'Адрес для вывода',
    balance: 'Доступный остаток',
    money: 'Сумма вывода',
    account: 'Фактическая сумма',
    serviceCharge: 'Комиссия для вывода составляет',
    submit: 'Отправить',
    ipt: 'Сумма вывода не должна превышать доступный остаток',
    isSub: 'Заявка отправлена',
    isAddress: 'Пожалуйста, сначала свяжите адрес для вывода',
    noAccount: 'Недостаточно средств для вывода'
  },
  // 充值页面
  rechargePage: {
    title: 'Пополнение счета',
    submit: 'Отправить',
    reMode: 'Способ пополнения',
    reMoney: 'Сумма пополнения',
    certificate: 'Загрузить документ',
    moneyMsg: 'Пожалуйста, введите сумма',
    qrCode: 'Пополнение счета через QR-код',
    copyClick: 'Нажмите, чтобы скопировать',
    copy: 'Скопировано',
    success: 'Пополнение счета успешно',
    submitMsg: 'Заявка отправлена, в процессе проверки',
    load: 'Загрузка...',
    loadSucess: 'Загрузка успешна',
    loadError: 'Загрузка неудачна, попробуйте еще раз',
    isPz: 'Пожалуйста, загрузите документ',
    rechargeMsg: 'Пожалуйста, переведите эквивалентную сумма по одному из способов, чтобы избежать задержек в финансовой проверке',
    detail: 'Детали пополнения',
    orderId: 'Номер заказа',
    time: 'Время',
    plase: 'Пожалуйста, используйте аккаунт',
    tran: 'Перевод денег',
    toAcc: 'на аккаунт',
    newAccount: 'Сумма, полученная на данный момент',
    results: 'Получение результата'
  },
  // 邀请好友页
  InvitePage: {
    title: 'Пригласить друзей',
    InviteCode: 'Пригласительный код',
    InviteText: 'Пригласите друзей и получите крупные доходы. Пригласите сейчас!',
    copyClick: 'Нажмите, чтобы скопировать',
    copy: 'Скопировано'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Личная информация',
    phone: 'Номер телефона',
    pasd: 'Пароль для входа',
    edit: 'Изменить',
    yPasd: 'Пожалуйста, введите старый пароль',
    yPasdMsg: 'Старый пароль не должен быть пустым!',
    newPasd: 'Пожалуйста, введите новый пароль',
    newPasdMsg: 'Новый пароль не должен быть пустым',
    nowPasd: 'Подтвердить новый пароль',
    nowPasdMsg: 'Подтверждение пароля не должно быть пустым',
    close: 'Отменить',
    submit: 'Ок',
    address: 'Пожалуйста, введите адрес для изменения',
    sucess: 'Адрес для вывода успешно изменен',
    goLogin: 'Пароль успешно изменен. Пожалуйста, войдите снова',
  },
  // 资金明细页
  financialPage: {
    title: 'История финансовых операций',
    noMore: 'Больше нет',
    amount: 'Сумма',
    recharge: 'Пополнение счета',
    orderSy: 'Доход от заказа',
    distributionSy: 'Доход от дистрибуции',
    other: 'Другое',
    payment: 'Оплата заказа',
    withdrawal: 'Вывод средств',
    frozen: 'Заблокировано',
    time: 'Время',
    noData: 'Нет данных',
    refresh: 'Обновление успешно',
    Withdrawn: 'Выведено',
    shsb: 'Проверка неудачна',
    shz: 'В процессе проверки'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: 'История пополнения счета',
    noMore: 'Больше нет',
    amount: 'Сумма',
    pendingPayment: 'Ожидается оплата',
    paymentSec: 'Оплата успешна',
    paymentFail: 'Оплата неудачна',
    refund: 'Возврат средств',
    time: 'Время',
    noData: 'Нет данных',
    refresh: 'Обновление успешно'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'История выводов средств',
    noMore: 'Больше нет',
    amount: 'Сумма',
    withdrawal: 'Вывод средств',
    time: 'Время',
    noData: 'Нет данных',
    refresh: 'Обновление успешно'
  }
}