export default {
  // 登录页
  loginPage: {
    area: 'Prefisso telefonico',
    areaMsg: 'Si prega di selezionare il prefisso telefonico',
    phone: 'Telefono',
    phoneMsg: 'Si prega di inserire il numero di telefono',
    name: 'Nome utente',
    nameMsg: 'Si prega di inserire il nome utente',
    password: 'Password',
    passwordMsg: 'Si prega di inserire la password',
    confirmPassword: 'Conferma password',
    confirmpsd: 'Conferma password', // Note: This is redundant, use 'confirmPassword' consistently
    code: 'Codice di raccomandazione',
    codeMsg: 'Si prega di inserire il codice di raccomandazione',
    termes: 'Registrandoti accetti i nostri termini e condizioni',
    rappelez: 'Ricordami',
    register: 'Registrazione',
    login: 'Accesso',
    account: 'Già possiedi un account?',
    gologing: 'Accedi',
    noAccount: 'Non hai un account?',
    goregister: 'Registrati',
    pasdMage: 'La password deve contenere da 6 a 20 lettere e cifre',
    loginSuccess: 'Accesso riuscito',
    registerSuccess: 'Registrazione riuscita, si prega di accedere',
    clause: `Si prega di selezionare l'accettazione delle clausole!`,
    noPasd: 'Le password non coincidono!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Si prega di inserire il prefisso telefonico internazionale',
    sub: 'Conferma'
  },
  // tabbar页
  tabbarPage: {
    home: 'Home',
    order: 'Ordini',
    trade: 'Transazioni',
    team: 'Team',
    user: 'Utente'
  },
  // 首页
  homePage: {
    income: 'Guadagni cumulati',
    myService: 'Miei servizi',
    about: 'Chi siamo',
    help: 'Aiuto',
    customer: 'Servizio clienti',
    partner: 'Partner'
  },
  // 订单页
  orderPage: {
    order: 'Ordine',
    notFinished: 'Non completato',
    completed: 'Completato',
    more: 'Non ci sono altri elementi',
    orderNum: 'Numero di ordine',
    tradeTime: 'Orario di transazione',
    amount: 'Importo',
    newTaskNum: 'Numero di attività correnti',
    expectedReturns: 'Guadagni previsti',
    noData: 'Nessun dato disponibile',
    placeOrder: 'Invia ordine',
    cancel: 'Annulla',
    close: 'Chiudi',
    noBalance: 'Saldo insufficiente',
    needRecharge: 'Necessità di ricarica',
    gorecharge: 'Vuoi procedere alla ricarica?'
  },
  // 交易页
  tradePage: {
    title: 'Transazioni',
    account: 'Saldo del conto',
    introduceTitle: 'Introduzione alle transazioni',
    introduceText: `TiktokShop utilizza i feedback di TiktokShop giornalieri per mostrare i prodotti che necessitano di miglioramenti nelle vendite. Gli utenti devono solo fare clic sull'ordine e il sistema genererà automaticamente una firma dell'ordine. Gli utenti pagano l'importo dell'ordine tramite blockchain USDT, ottenendo ogni volta una commissione superiore al 0.6%, e il sistema assegna casualmente ordini di ricompense di grande importo.`,
    Completed: 'Numero di completamenti',
    totalTask: 'Numero totale di attività',
    start: 'Inizia la corrispondenza',
    dayProfit: 'Guadagni odierni',
    tradeTime: 'Orario di transazione',
    price: 'Importo',
    profit: 'Guadagni previsti',
    startTrade: 'Inizia la transazione',
    close: 'Annulla'
  },
  // 团队页
  teamPage: {
    title: 'Team',
    money: 'Importo',
    commission: 'Commissione',
    receive: 'Ricevi',
    myTeam: 'Mio team',
    total: 'Numero totale',
    user: 'Utente',
    contribution: 'Contributo (attività)',
    status: 'Stato'
  },
  // 我的页面
  userPage: {
    noLogin: 'Non connesso',
    id: 'Numero',
    account: 'Mio conto',
    freezeAmount: 'Importo bloccato',
    withdrawal: 'Prelievo',
    recharge: 'Ricarica',
    invitation: 'Invita i tuoi amici',
    invitationText: 'Ogni profitto guadagnato dagli amici registrati ti darà una commissione corrispondente',
    nowInvitation: 'Invita ora',
    userInfo: 'Informazioni personali',
    financial: 'Dettagli finanziari',
    rechargeRecord: 'Cronologia ricariche',
    withdrawalRecord: 'Cronologia prelievi',
    lang: 'Lingua',
    out: 'Disconnetti',
    copy: 'Copiato correttamente',
    load: 'Caricamento in corso...',
    outMsg: 'Vuoi davvero disconnetterti?'
  },
  // 提现页面
  withdrawalPage: {
    title: 'Prelievo',
    mode: 'Modalità di pagamento',
    address: 'Indirizzo di prelievo',
    balance: 'Saldo disponibile',
    money: 'Importo da prelevare',
    account: 'Importo effettivo',
    serviceCharge: 'Commissione di prelievo è di',
    submit: 'Invia',
    ipt: 'L’importo da prelevare non può essere superiore al saldo disponibile',
    isSub: 'Richiesta inviata',
    isAddress: 'Per favore, prima bind l’indirizzo di prelievo',
    noAccount: 'Importo disponibile insufficiente per il prelievo'
  },
  // 充值页面
  rechargePage: {
    title: 'Ricarica',
    submit: 'Invia',
    reMode: 'Modalità di ricarica',
    reMoney: 'Importo di ricarica',
    certificate: 'Carica le credenziali',
    moneyMsg: 'Inserisci l’importo',
    qrCode: 'Scansiona il codice QR per ricaricare',
    copyClick: 'Clicca per copiare',
    copy: 'Copiato correttamente',
    success: 'Ricarica effettuata',
    submitMsg: 'Invio riuscito, in corso di verifica',
    load: 'Caricamento in corso...',
    loadSucess: 'Caricamento completato',
    loadError: 'Caricamento fallito, si prega di riprovare',
    isPz: 'Per favore, carica la prova',
    rechargeMsg: "Si prega di scegliere il seguente metodo per effettuare un bonifico di pari importo, al fine di evitare ritardi nelle verifiche finanziarie",
    detail: "Dettagli del ricarico",
    orderId: "Numero d'ordine",
    time: "Ora",
    plase: `Si prega di utilizzare l'account`,
    tran: 'Versamento in',
    toAcc: 'nel conto',
    newAccount: 'Importo ricevuto attualmente',
    results: 'Ottieni i risultati'
  },
  // 邀请好友页
  InvitePage: {
    title: 'Invita amici',
    InviteCode: 'Codice di invito',
    InviteText: 'Invita amici e guadagna grandi profitti. Invita ora!',
    copyClick: 'Clicca per copiare',
    copy: 'Copiato correttamente',
  },
  // 个人信息页
  userInfoPage: {
    title: 'Informazioni personali',
    phone: 'Numero di telefono',
    pasd: 'Password di accesso',
    edit: 'Modifica',
    yPasd: 'Inserisci la vecchia password',
    yPasdMsg: 'La vecchia password non può essere vuota!',
    newPasd: 'Inserisci la nuova password',
    newPasdMsg: 'La nuova password non può essere vuota',
    nowPasd: 'Conferma password',
    nowPasdMsg: 'La conferma password non può essere vuota',
    close: 'Annulla',
    submit: 'Conferma',
    address: 'Inserisci l’indirizzo da modificare',
    sucess: 'Modifica dell’indirizzo di prelievo riuscita',
    goLogin: 'Modifica della password riuscita, si prega di riconnettersi',
  },
  // 资金明细页
  financialPage: {
    title: 'Dettagli finanziari',
    noMore: 'Non ci sono altri',
    amount: 'Importo',
    recharge: 'Ricarica',
    orderSy: 'Guadagno ordini',
    distributionSy: 'Guadagno vendita diretta',
    other: 'Altro',
    payment: 'Ordine di pagamento',
    withdrawal: 'Prelievo',
    frozen: 'Bloccato',
    time: 'Ora',
    noData: 'Nessun dato',
    refresh: 'Aggiornamento riuscito',
    Withdrawn: 'Prelevato',
    shsb: 'Verifica fallita',
    shz: 'In corso di verifica'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: 'Cronologia ricariche',
    noMore: 'Non ci sono altri',
    amount: 'Importo',
    pendingPayment: 'In attesa di pagamento',
    paymentSec: 'Pagamento riuscito',
    paymentFail: 'Pagamento fallito',
    refund: 'Rimborso',
    time: 'Ora',
    noData: 'Nessun dato',
    refresh: 'Aggiornamento riuscito'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Cronologia prelievi',
    noMore: 'Non ci sono altri',
    amount: 'Importo',
    withdrawal: 'Prelievo',
    time: 'Ora',
    noData: 'Nessun dato',
    refresh: 'Aggiornamento riuscito',
  }
}