import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/home' },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: () => import('@/views/NotFound.vue'),
    },
    {
      path: '/loginIndex',
      name: 'loginIndex',
      component: () => import('@/views/login/loginIndex.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/loginArea',
      name: 'loginArea',
      component: () => import('@/views/login/loginArea.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home/home.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/order/order.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/trade',
      name: 'trade',
      component: () => import('@/views/trade/trade.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('@/views/team/team.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/user/user.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/home/about.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/help',
      name: 'help',
      component: () => import('@/views/home/help.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/goWithdrawal',
      name: 'goWithdrawal',
      component: () => import('@/views/user/goWithdrawal.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/goRecharge',
      name: 'goRecharge',
      component: () => import('@/views/user/goRecharge.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/inviteFriends',
      name: 'inviteFriends',
      component: () => import('@/views/user/inviteFriends.vue'),
      meta: {
        title: '',
      }
    },

    {
      path: '/userInfo',
      name: 'userInfo',
      component: () => import('@/views/user/userInfo.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/financial',
      name: 'financial',
      component: () => import('@/views/user/financial.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/recharge',
      name: 'recharge',
      component: () => import('@/views/user/recharge.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/withdrawal',
      name: 'withdrawal',
      component: () => import('@/views/user/withdrawal.vue'),
      meta: {
        title: '',
      }
    },
    {
      path: '/rechargeDetail',
      name: 'rechargeDetail',
      component: () => import('@/views/user/rechargeDetail.vue'),
      meta: {
        title: '',
      }
    }
  ]
})

// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  if (localStorage.getItem('token') || localStorage.getItem('LoginIn') || to.path === '/loginIndex' || to.path === '/loginArea' || to.path === '/home' || to.path === '/order' || to.path === '/trade' || to.path === '/team' || to.path === '/user' || to.path === '/help' || to.path === '/about') {
    // 放行，进入下一个路由
    next()
  } else {
    next('/loginIndex')
  }
})

export default router

